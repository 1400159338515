import Spinner from './Spinner';

type Props = {
  height?: 'h-screen' | 'h-[75vh]' | 'h-full';
};

const CenterSpinner = ({ height = 'h-screen' }: Props) => (
  <div className={`flex items-center justify-center ${height}`}>
    <Spinner />
  </div>
);

export default CenterSpinner;
