import CenterSpinner from '@otovo/shared/components/LoadingScreens/CenterSpinner';
import { useRouter } from 'next/router';
import { Otovo$Config } from '@otovo/shared/types/otovoweb';
import { Sanity$CampaignPage } from '@otovo/shared/types/sanityTypes';
import StorefrontPage from '../StorefrontPage';
import SanityLandingPage from './SanityLandingPage';

type Props = {
  config: Otovo$Config;
  sanityDocument: Sanity$CampaignPage;
};

const GenericLandingPage = ({ config, sanityDocument }: Props) => {
  const router = useRouter();
  const { hideMenu, hideFooter } = sanityDocument || {};

  if (router.isFallback) {
    return <CenterSpinner />;
  }
  return (
    <StorefrontPage
      config={config}
      hideMenu={hideMenu}
      hideFooter={hideFooter}
      sanityDocument={sanityDocument}
    >
      <SanityLandingPage page={sanityDocument} />
    </StorefrontPage>
  );
};

export default GenericLandingPage;
