import { Otovo$Config } from '@otovo/shared/types/otovoweb';
import {
  Sanity$CampaignPage,
  Sanity$DocumentType,
} from '@otovo/shared/types/sanityTypes';
import {
  getLandingPageStaticPaths,
  getLandingPageStaticProps,
} from '../../config/getProps';
import GenericLandingPage from '../../components/SanityLandingPage/GenericLandingPage';

type Props = {
  config: Otovo$Config;
  sanityDocument: Sanity$CampaignPage;
};

const LandingPage = ({ config, sanityDocument }: Props) => {
  return <GenericLandingPage config={config} sanityDocument={sanityDocument} />;
};

const PAGE_TYPE: Sanity$DocumentType = 'landingPage';

export const getStaticPaths = getLandingPageStaticPaths(PAGE_TYPE);
export const getStaticProps = getLandingPageStaticProps(PAGE_TYPE);

export default LandingPage;
